<template>
  <section id="BloqueTres">
    <div class="barra">
      <span class="barra__linea"></span>
      <svg viewBox="0 0 29.9 29.9" class="barra__flor">
        <path
          d="M19.6 15.6c.1 2.5-1.8 4.6-4.1 4.8-2.6.2-4.8-1.6-5.1-4.1-.1-1.2.1-2.4.7-3.5.9-1.7 2.2-3 3.8-3.9.1 0 .2-.1.2 0 1.9 1.1 3.4 2.7 4.2 4.8.2.7.3 1.3.3 1.9zm-5.2 3.6v-1.4c0-.1 0-.2-.1-.3-.9-.7-1.7-1.4-2.6-2l-.1-.1c-.3 2 1.1 3.6 2.8 3.8zm1.2 0c1.8-.3 3.1-2.1 2.9-3.8l-2.7 2.1s-.1.1-.1.2c-.1.5-.1 1-.1 1.5zm-3.9-5.1 2.7 2.1v-1.3c0-.2.1-.5 0-.7-.1-.2-.4-.3-.6-.5-.4-.3-.9-.7-1.3-1-.3.4-.6.9-.8 1.4zm3.9 2.1c.1-.1.1-.1.2-.1.7-.6 1.4-1.1 2.1-1.6.4-.3.4-.3.2-.7-.2-.4-.4-.7-.5-1.1-.7.5-1.3 1-1.9 1.5l-.1.1v1.9zm0-3.6c.4-.3.8-.6 1.2-1-.4-.4-.8-.8-1.2-1.1v2.1zm-1.2 0v-2.1l-1.2 1.2 1.2.9z"
        />
      </svg>
    </div>

    <div class="BloqueTres__titular">
      <div class="titular__encabezado">
        <p class="encabezado__texto">{{datos.encabezado}}</p>
      </div>

      <div class="titular__descripcion">
        <p class="descripcion__texto" v-for="texto in datos.titular" :key="texto">{{texto}}</p>
        <img
          class="descripcion__imagen"
          loading="lazy"
          width="1270"
          height="635"
          :src="datos.logo_eco"
          alt="Logo Eco Bayer"
        />
      </div>
    </div>

    <div class="BloqueTres__contenido">
      <div class="contenido__informacion">
        <p class="informacion__descripcion" v-for="texto in datos.introduccion_eco" :key="texto">{{texto}}</p>
      </div>

      <div class="contenido__listado">
        <ul class="contenedor__listado">
          <li class="listado__texto" v-for="texto in datos.listado_eco" :key="texto">{{texto}}</li>
        </ul>
      </div>
    </div>
    <FondoAnimado />
  </section>
</template>

<script>
//import FondoAnimado from "./BloqueTresSecciones/FondoAnimado.vue";
import { defineAsyncComponent } from "vue";

export default {
  name: "BloqueTres",
  components: {
    FondoAnimado:defineAsyncComponent(() =>
      import(/* webpackChunkName: "FondoAnimado" */ "./BloqueTresSecciones/FondoAnimado.vue")
    ),
  },
  props: {
    bloque_tres: Object,
  },
  setup(props) {
    const datos = props.bloque_tres;
    return {datos}
  }
};
</script>